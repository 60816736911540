/*======================= GOOGLE FONTS ==============================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*======================= VARIABLES CSS ==============================*/
:root {
    --header-height: 3em;
    --footer-height: 4em;
    /*============== Colors ==============*/
    /*Color mode HSL (hue, saturation, lightness*/
    --hue: 0;
    --sat: 0%;
    --title-color: hsl(var(--hue), var(--sat), 20%);
    --title-color-dark: hsl(var(--hue), var(--sat), 0%);
    --text-color: hsl(var(--hue), var(--sat), 46%);
    --body-color: hsl(var(--hue), var(--sat), 96%);
    --container-color: #fff;
    --hover-color: hsl(216, 81%, 30%);


    /*============== Font & typography ==============*/
    --body-font: "Poppins", sans-serif;
    --big-font-size: 3.5rem;
    --h1-font-size: 2.45rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
    --tiny-font-size: 0.625rem;

    /*Font weight*/
    --font-thin: 200;
    --font-normal: 400;
    --font-medium: 500;
    --font-semi-bold: 600;

    /*============== Margines bottom ==============*/
    --mb-0-25: 0.25rem;
    --mb-0-5: 0.5rem;
    --mb-0-75: 0.75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /*============== Z-index ==============*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;

}

/*============== Responsive typography ==============*/
@media screen and (max-width: 992px) {
    :root{
        --big-font-size: 2.85rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1rem;
        --normal-font-size: 0.938rem;
        --small-font-size: 0.813rem;
        --smaller-font-size: 0.75rem;
    
    }
}

/*======================= BASE ==============================*/
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body,
button,
input,
textarea {
    font-family: var(--body-font);
    font-size: var(--normal-font-size)
}

body {
    background-color: var(--body-color);
    color: var(--text-color);
}

h1, h2, h3 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold); 
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
    border: none;
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

/*======================= REUSABLE CSS CLASSES ==============================*/
.section {
    padding: 4rem 0 2rem;
}

.section__title {
    font-size: var(--h1-font-size);
    color: var(--title-color)
}

.section__subtitle {
   display: block;
   font-size: var(--small-font-size);
   margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
    text-align: center;
}

/*======================= LAYOUT ==============================*/
.container {
    max-width: 968px;
    margin-left: auto;
    margin-right: auto;
}

.grid {
    display: grid;
    gap: 1.5rem;
}

/*======================= BUTTONS ==============================*/
.button {
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
}

.button:hover {
    background-color: var(--title-color-dark);
}

.button__icon {
    margin-left: var(--mb-0-5);
}

.button-flex {
    display: inline-flex;
    align-items: center
}



/*======================= BREAKPOINTS ==============================*/
/*Large devices*/
@media screen and (max-width: 982px) 
{
    .container {
        margin-left: var(--mb-2-5);
        margin-right:var(--mb-2-5);
    }

}

/*Medium devices*/
@media screen and (max-width: 768px) 
{
    
}

/*Small devices*/
@media screen and (max-width: 576px) 
{
    .container {
        margin-left: var(--mb-0-25);
        margin-right:var(--mb-0-25);
    
    }

}


    

 




