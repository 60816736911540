.research__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.research__list {
    list-style-type: none;
    padding-left: 0;
}

.research__item {
    margin-bottom: 1.5rem;
}

.research__link {
    display: block; 
    text-decoration: none;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.research__link:hover {
    background-color: #f5f5f5;
    border-color: #ccc;
}

.research__title {
    font-size: var(--h2-font-size);
    margin-bottom: 0.2rem;
}

.research__subtitle {
    font-size: var(--small-font-size);
    color: #555;
}

.research__year {
    font-size: 0.9rem;
    color: #888;
    margin-top: 0.5rem; /* Space between subtitle and year */
}

.research__link:hover .research__title {
    color: var(--hover-color);
}


/*Medium devices*/
@media screen and (max-width: 768px) 
{
    #research {
        padding: 3rem 0;
    }


}

/*Small devices*/
@media screen and (max-width: 655px) 
{

   #research {
        padding: 2rem 1rem;
    }
    .research__item {
        margin: 1rem; 
    }

    .research__title {
        font-size: var(--small-font-size);
    }

    .research__subtitle {
        font-size: var(--smaller-font-size);
    }

}