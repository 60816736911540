.home__container {
    row-gap: 7rem;
}

.home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    padding-top: 5.5rem;
    align-items: center;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__social-repec {
    font-size: 0.9rem;
    color: var(--title-color);
}

.home__social-icon:hover,
.home__social-repec:hover {
    color: var(--hover-color);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.3rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before {
    content:'';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 0.8rem;
}

.home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.home__img {
    background: url(../../assets//bge_photo.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 8%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 428px;
    animation: profile__animate 8s ease-in-out infinite 1s;
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%; 
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}



/*======================= BREAKPOINTS ==============================*/
/*Large devices*/
@media screen and (max-width: 982px) 
{
    .home__content {
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home__subtitle {
        padding-left:  3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width:42px;
        top:0.8rem;
    }

    .home__description {
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }

    .home__img {
        width: 250px;
        height: 356px;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 8%);
    }
}

/*Medium devices*/
@media screen and (max-width: 768px) 
{

    .section {
        padding: 0 1rem;
    }

    .home__content {
        grid-template-columns: 40px repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home__title {
        font-size: var(--h1-font-size);
    }
    
    
}

/*Small devices*/
@media screen and (max-width: 655px) 
{

    .home__title {
        font-size: var(--big-font-size);
    }

    .section {
        padding: 0 2rem 6rem 2rem;
    }
   
    .home__content {
        grid-template-columns: 1fr; 
        grid-template-areas: 
            "social" 
            "img" 
            "data"; 
        padding-top: 1.5rem;
    }
    

    .home__social {
        grid-area: social; 
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        row-gap: 0; 
    }
   

    .home__img {
        grid-area: img; 
        justify-self: center;
        width: 300px;
        height: 428px;
        box-shadow: inset 0 0 0 3px rgb(255 255 255 / 8%);
    }


    .home__data {
        grid-area: data; 
    }

}

