* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


#downloads {
  padding: 1rem 0rem;
}

.downloads__container {
    /* margin-top: 4rem; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
}

.downloads__list {
    list-style-type: none;
    padding-left: 0;

}

.downloads__item {
    margin-bottom: 1.7rem;
}

.downloads__link {
    display: block;
    text-decoration: none;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: all 0.3s ease;
    /* text-align: left; */
    color: var(--title-color); 
}

.downloads__link:hover {

    border-color: var(--hover-color);
    color: var(--hover-color);
}

.downloads__link:hover .downloads__title {
    color: var(--hover-color);
}


.downloads__link:hover .downloads__type {
    color: var(--body-color); /* Change text color on hover */
}

.downloads__title {
    font-size: var(--h2-font-size);
    margin-bottom: 0.3rem;
    position: relative;
    z-index: 1; /* Text stays on top */
    /* display: inline-flex; */
    align-items: center; /* Vertically center text */
    justify-content: center; /* Horizontally center text */
    height: 100%; /* Ensure the text takes the full height of the block */
    padding-left: 0.3rem;;
    line-height: 1.2;
    margin-bottom: 0.2rem;
}
.downloads__type {
    font-size: var(--medium-font-size);
    color: var(--default-color); /* Default text color */
    margin: 0.7rem 0;
    font-weight: var(--font-normal);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transform: scaleY(1.4);
    letter-spacing: 0.3em;
    position: relative;
    z-index: 1; /* Text stays on top */
    display: inline-flex; /* Use flexbox */
    align-items: center; /* Vertically center text */
    justify-content: center; /* Horizontally center text */
    height: 100%; /* Ensure the text takes the full height of the block */
    padding-left: 0.3rem;
    margin-bottom: 0.8rem;
    
    
}


.downloads__type::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hover-color); /* Color of the block */
    opacity: 0;
    visibility: hidden; /* Initially hidden */
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Fade in */
    z-index: -1; /* Behind the text */
}

.downloads__link:hover .downloads__type::before {
    opacity: 1;
    visibility: visible; /* Show the block */
}



.downloads__subtitle {
    font-size: var(--medium-font-size);
    margin: 0;
    color: #555;
}

.downloads__year {
    font-size: 0.9rem;
    color: #888;
    margin-top: 0.5rem;
}




@media screen and (max-width: 995px) {
    
.downloads__type {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
}

.downloads__subtitle {
    font-size: var(--big-font-size);
    text-align: left;
    color: #555;
}

.downloads__year {
    font-size: 0.9rem;
    color: #888;
    margin-top: 0.5rem;
}

}






@media screen and (max-width: 768px) {
#downloads {
    padding: 1rem 0rem;
    margin-bottom: 4rem;

    }

    .downloads__item {
        margin-bottom: 2.1rem;
    }

    .downloads__type {
        font-size: var(--h2-font-size);
        margin-bottom: 0.2rem;
    }

    .downloads__subtitle {
        font-size: var(--medium-font-size);
        text-align: left;
        color: #555;
    }
    
}