.resume__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 2rem;
}

.resume__iframe {
    width: 90%;
    height: 900px;
    border: none; 
}

.resume__fallback {
    margin-top: 1rem;
    font-size: 1rem;
    margin-top: 2rem;
}

.resume__fallback a {
    color: var(--hover-color);
    text-decoration: none;
}

.resume__fallback a:hover {
    text-decoration: overline;
}

@media (max-width: 768px) {
    .resume__iframe {
        height: 700px;  
    }

    .resume__container {
        margin-bottom: 8rem;
    }

   
    
}

@media (max-width: 576px) {
    .resume__container iframe {
        display: none 
    }

    .resume__fallback {
        margin-top: 1rem;
        font-size: 1rem;
        
        border: 1px var(--hover-color) solid;
        border-radius: 20px;
        padding: 2rem;
    }
}