/* Ensure the Zaragoza page takes up at least the full height of the screen */
.zaragoza-page {
    min-height: calc(100vh - var(--footer-height)); /* Subtract footer height from viewport height */
    /* display: grid; */
    grid-template-columns: 1fr;
    grid-template-areas: "content";  /* Single column layout */
    padding: 5rem 2rem;  /* Added margins */
    flex: 1;
}

.zaragoza-page .container {
    padding: 2rem 0rem;
    margin: 0;
}


.zaragoza__title {
    text-align: left;
    padding-left: 0;
    padding-top: 2rem;
}

.zaragoza__subtitle {
    position: relative;
    text-align: left;
    font-size: var(--h3-font-size);
    padding-left: 5.3rem;
    font-weight: var(--font-normal);
    margin-top: 0;
    margin-bottom: var(--mb-1);
}

.zaragoza__subtitle::before {
    content:'';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 0.8rem;
}


/* Title and subtitle styles */
.zaragoza-page h1 {
    font-size: var(--h1-font-size);
    /* margin-bottom: 2rem; */
    color: var(--hover-color);  /* White text for contrast */
}

.zaragoza-page p {
    font-size: var(--normal-font-size);
    /* color: var(--text-color); */
    max-width: 600px;
    /* margin: 0 auto; */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {

    .zaragoza-page {
        padding: 1rem 2rem;
        margin: 0;      
    }


    .zaragoza__title {
        padding-top: 3rem;
    }

    .zaragoza__subtitle {
        margin-bottom: 0;
    }


  .zaragoza-page .container {
        padding: 4rem 0;
        margin: 0;
    }
    .zaragoza-page h1 {
        font-size: 2.5rem;
    }

}

/* Extra small devices */
@media screen and (max-width: 576px) {
    .zaragoza-page {
        padding: 3rem 1rem;
        
    }

    .zaragoza-page .container {
        padding: 1rem 0;
        margin: 1rem 0;
    }

    .zaragoza-page h1 {
        font-size: 2rem;
    }

    .zaragoza-page p {
        font-size: 1rem;
    }
}
